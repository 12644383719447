
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";

$.fn.intractiveChart = function (styles) {
  function clone(src) {
    return Object.assign({}, src);
  }
  function _element(el, parent) {
    var $el = $(`<${el}></${el}>`);
    if (parent) {
      parent.append($el);
    }
    return $el;
  }

  function _tr(parent) {
    return _element("tr", parent);
  }
  function _td(parent) {
    return _element("td", parent);
  }
  function _th(parent) {
    return _element("th", parent);
  }
  function _label(parent) {
    return _element("label", parent);
  }
  function _span(parent) {
    return _element("span", parent);
  }
  function _checkbox(parent) {
    return _element("input", parent)
      .attr("type", "checkbox")
      .addClass(styles.checkbox);
  }

  var $self = $(this);
  var locale = $self.data("locale") || "pl-PL";
  console.log(locale);
  var numberStyle = $self.data("number-style") || {};
  var $thead = $("<thead></thead>");
  var $tbody = $("<tbody></tbody>");
  var $table = $("<table></table>")
    .addClass(styles.table)
    .append($thead)
    .append($tbody);

  function createTable(chart, data, format) {
    var $trThead = _tr($thead);
    var $checkAll = _checkbox();

    function refreshCurrentChart() {
      refreshChart(chart, data.series);
    }

    function ckeckAll() {
      var checked = true;
      $tbody.find("[data-row]").each(function () {
        if (!this.checked) {
          checked = false;
        }
      });

      $checkAll[0].checked = checked;
    }

    function clickAll() {
      var ckecked = this.checked;
      $tbody.find("[data-row]").each(function () {
        this.checked = ckecked;
      });
      refreshCurrentChart();
    }

    $checkAll.on("change", clickAll);

    _label(_th($trThead)).append($checkAll).append($self.data("check-all"));

    for (var col in data.xAxis.categories) {
      var category = data.xAxis.categories[col];

      _span(_th($trThead)).html(category);
    }

    for (var row in data.series) {
      var seria = data.series[row];
      var $trBody = _tr($tbody);

      var $checkRow = _checkbox()
        .attr("data-row", row)
        .on("change", refreshCurrentChart)
        .on("change", ckeckAll);
      _label(_td($trBody)).append($checkRow).append(seria.name);

      for (var col in seria.data) {
        var value = seria.data[col].toLocaleString(locale, format[row]);

        _td($trBody).append(value);
      }
    }
    $self.before($table);
  }

  function setChartData(chart, data) {
    while (chart.series.length > 0) {
      chart.series[0].remove(false);
    }

    chart.redraw();

    if (data.length > 0) {
      for (var i in data) {
        chart.addSeries(data[i], false);
      }
      chart.redraw();
      $self.show();
    } else {
      $self.hide();
    }
  }

  function refreshChart(chart, series) {
    var data = [];
    $tbody.find("[data-row]:checked").each(function () {
      var row = $(this).attr("data-row");
      data.push(series[row]);
    });
    setChartData(chart, data);
  }

  $.get(
    $self.data("chart-url"),
    null,
    function (response) {
      var chartData = clone(response.data);
      chartData.series = [];
      var chart = Highcharts.chart($self.attr("id"), chartData);

      createTable(chart, response.data, response.format);

      refreshChart(chart, response.data.series);
    },
    "json"
  );
};

$(window).on("load", function () {
  if ($("#filtering").length > 0) {
    // tabby.init();

    $("#filtering .tabs li").on("click", function (e) {
      e.preventDefault();
      let id = $(this).data("id");
      $(".tabs li").removeClass("acctived");
      $(this).toggleClass("acctived");
      $(".tabs-pane").removeClass("active");
      $(".tabs-pane").each(function (i) {
        if (id == i) {
          $(this).addClass("active");
        }
      });
    });
  }

  var styles = {
    table: "filter-border",
    checkbox: "in",
  };

  $("[data-chart-url]").each(function () {
    $(this).intractiveChart(styles);
  });
});