const toolbar = $(".main .toolbar");
if (toolbar.length > 0) {
  if ($(window).width() > 575) {
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > $(window).height() / 5) {
        toolbar.css({
          position: "fixed",
          top: "60px",
        });
      } else {
        toolbar.removeAttr("style");
      }
    });
  }
}
const contentToolbar = $(".content .toolbar");
if (contentToolbar.length > 0) {
  if ($(window).width() > 575) {
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > $(window).height()) {
        contentToolbar.css({
          position: "fixed",
          top: "60px",
        });
      } else {
        contentToolbar.removeAttr("style");
      }
    });
  }
}
