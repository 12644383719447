const labels = {
  pl: {
    more: "więcej",
    less: "mniej",
  },
  en: {
    more: "more",
    less: "less",
  },
};

$(".accordion__btn").on("click", function () {
  const target = $(this).parent().parent().attr("id");
  const lang = $("html").attr("lang");
  setTimeout(function () {
    scrollTo(target);
  }, 600);
  if ($(this).hasClass("collapsed")) {
    lang == "pl"
      ? $(".accordion__btn-more").html(labels[lang].more)
      : $(".accordion__btn-more").html(labels[lang].more);
    lang == "pl"
      ? $(this).children(".accordion__btn-more").html(labels[lang].less)
      : $(this).children(".accordion__btn-more").html(labels[lang].less);
  } else {
    lang == "pl"
      ? $(this).children(".accordion__btn-more").html(labels[lang].more)
      : $(this).children(".accordion__btn-more").html(labels[lang].more);
  }
});

function scrollTo(id) {
  $("html, body").animate(
    {
      scrollTop: $("#" + id).offset().top,
    },
    1000
  );
}
