const labels = {
    pl: {
      more: "więcej",
      less: "mniej",
    },
    en: {
      more: "more",
      less: "less",
    },
  };

$(".submenu__items--more").hide('fast');
$(".submenu__items__btn").html(labels[$("html").attr("lang")].more);

$(".submenu__items__btn").on("click", function () {
    const lang = $("html").attr("lang");
    $(this).toggleClass('visible');
    if($(this).hasClass("visible")){
        $(".submenu__items__btn").html(labels[lang].less);
        $(this).siblings( ".submenu__items--more" ).show('fast');
    } else {
        $(this).siblings( ".submenu__items--more" ).hide('fast');
        $(".submenu__items__btn").html(labels[lang].more);
    }
});