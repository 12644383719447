import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";

Exporting(Highcharts); // Initialize exporting module.

$("[data-chart]").each((index, chartElement) => {
  const chartObj = $(chartElement).data("chart");

  chartObj.data = {
    ...chartObj.data,
    title: {
      ...chartObj.data.title,
      useHTML: true,
      style: {
        color: null,
        "font-family": null,
        "font-size": null,
      },
    }
  };

  if (chartObj.data.language && chartObj.data.language === "en") {
    chartObj.data.lang = {
        downloadJPEG: "Download JPEG image",
        downloadPDF: "Download PDF document",
        downloadPNG: "Download PNG image",
        downloadSVG: "Download SVG vector image",
        downloadXLS: "Download XLS",
        thousandsSep: ","
    };
  } else {
    chartObj.data.lang = {
        downloadJPEG: "Pobierz jako JPEG image",
        downloadPDF: "Pobierz jako PDF document",
        downloadPNG: "Pobierz jako PNG image",
        downloadSVG: "Pobierz jako SVG vector image",
        downloadXLS: "Pobierz jako XLS",
        thousandsSep: ""
    };
  }

  Highcharts.setOptions({ lang: { ...chartObj.data.lang } });
  Highcharts.chart(chartElement.id, chartObj.data);
});
