
$(".font-size-toggle").on("click", function () {
    const html = $("html");
    console.log(html.attr('style'));
    if (!html.attr('style')) {
      html.css("font-size", "80%");
    } else {
      html.removeAttr("style");
    }
  });
  