//need to change in CMS
let pageName = window.location.pathname;
let addedPages = JSON.parse(localStorage.getItem("addedPages"));
showCounts(addedPages);
if (localStorage.getItem("addedPages") > 0 || addedPages) {
    generateLinks(addedPages);
}
$("#clipboard-add").on("click", function () {
  
  if (localStorage.getItem("addedPages") === null) {
    pages = [];
    pages.push(pageName);

    showCounts(pages);
    localStorage.setItem("addedPages", JSON.stringify(pages));
    let pageNameText = pageName.split("/");
    let linkClip = `<h4 class="clipboard-link"><a href="${pages}">${pageNameText[pageNameText.length-2]}</a></h4>`;
    $(".modal .pagesName").append(linkClip);
  } else {
    let pages = JSON.parse(localStorage.getItem("addedPages"));
    let count = pages.length;
    

    if (jQuery.inArray(pageName, pages) < 0) {
      //add array
      pages.push(pageName);
      showCounts(pages);
      localStorage.setItem("addedPages", JSON.stringify(pages));
      $(".modal .pagesName h4").remove();
      generateLinks(pages);
    }
  }
});
function generateLinks(pages){
    for (var i = 0; i < pages.length; i++) {
        let pageNameText = pages[i].split("/");
        let linkClip = `<h4 class="clipboard-link"><a href="${pages[i]}">${pageNameText[pageNameText.length-2]}</a></h4>`;
        $(".modal .pagesName").append(linkClip);
      }
}

function showCounts(arr) {
  if (arr != null) {
    let count = arr.length;
    let span = '<span class="count">' + count + "</span>";
    $("#clipboard-save .count").remove();
    $("#clipboard-save button").append(span);

    //animate
    $("#clipboard-save")
      .animate(
        {
          marginTop: "-10px",
        },
        "fast"
      )
      .animate({ marginTop: "0px" }, "fast");
  }
}

