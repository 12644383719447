document.querySelectorAll(".scroll-top").forEach((item) => {
  item.addEventListener("click", (event) => {
    topFunction();
  });
});

function topFunction() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}
